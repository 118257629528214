<template>
  <div class="m">
    <newTitle txt="How to Use"></newTitle>

    <!-- 使用方法 -->
    <div v-if="topData.introduce" class="usage_box">
      <div
        class="title"
        v-if="topData.introduce"
        v-html="topData.introduce"
      ></div>
      <!-- <div class="syff">
          <div class="syff_left">
            <p></p>
            <p></p>
          </div>
          <div v-if="usage[lang]" class="txt">{{usage[lang]}}</div>
          <div class="syff_right">
            <p></p>
            <p></p>
          </div>
        </div> -->
    </div>
    <div class="neiron" v-if="topData.content" v-html="topData.content"></div>
    <!-- 底部 -->
    <CBottom :lanPam="lanPam"></CBottom>
    <!-- <div class="btmBox">
      <p>Privacy Policy</p>
      - Copyright © Tripellet.com , All rights reserved -
    </div> -->
  </div>
</template>

<script>
import newTitle from "./Component_title.vue";
import { getProductListTop } from "@/api/hfIndex";
import CBottom from "./Component_bottom.vue";
export default {
  components: { newTitle, CBottom },
  data() {
    return {
      id: "",
      topData: {},
      lanListPam: {
        zh: "sc",
        zh_tw: "tc",
        en: "en",
        ja: "jp",
        ko: "kr",
      },
    };
  },
  activated() {
    this.id = this.$route.query.id;
    this.getProductListTopFn(this.id);
  },
  methods: {
    async getProductListTopFn(id) {
      let res = await getProductListTop({ lang: this.lanPam, id: this.id });
      this.topData = res.data.data;
      console.log("1111", res);
    },
  },
  computed: {
    lanPam() {
      return this.lanListPam[localStorage.getItem("locale")];
    },
  },
};
</script>

<style lang="scss" scoped>
.m {
  height: 100vh;
  max-width: 766px;
  margin: 0 auto;
  min-height: 100vh;
}
// 使用方法
.usage_box {
  background-color: #fffba0;
  padding: 18px 20px;
  // margin: 20px 15px;
  color: #1a1311;
  font-weight: 700;
  margin-top: 0;
  .title {
    font-size: 13px;
    line-height: 19px;
  }
  .syff {
    display: flex;
    justify-content: space-between;
    margin: 18px 0;
    .txt {
      font-size: 21px;
      margin: 0 8.5px;
    }

    .syff_left,
    .syff_right {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      p {
        display: inline-block;
        height: 1px;
        width: 100%;
        background-color: #707070;
        margin-bottom: 2px;
      }
    }
  }
  .neiron {
    line-height: 21px;
    font-size: 12px;
    span {
      color: #fa6460;
    }
  }
}

/deep/.neiron {
  img {
    width: 100% !important;
    height: unset !important;
  }
}

.btmBox {
  margin: 0 auto;
  max-width: 766px;
  text-align: center;
  background-color: #e5e5e5;
  padding: 5.3333vw 0 7.7333vw;
  font-size: 2.6667vw;
  p {
    margin-bottom: 0.5333vw;
  }
}
@media (min-width: 766px) {
  .btmBox {
    padding: 40px 0 58px;
    font-size: 20px;
    p {
      margin-bottom: 4px;
    }
  }
}
</style>
